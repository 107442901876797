export default function GradientHorizontal() {
  return (
    <div className={'absolute w-32 right-0 h-full text-right'}>
      <div
        className={'inline-block bg-gradient-to-l from-bg-grey-light to-transparent h-full w-24'}
      />
      <div className={'inline-block w-8 h-full bg-grey-light'}></div>
    </div>
  );
}
