import CheckoutRoutes from 'constants/routes/checkout';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { trackCartReminderToOverviewClick } from 'modules/tracking/events/events';
import Link from 'next/link';

export default function CartReminderCallToAction() {
  const { t } = useTranslation();

  const handleOnClick = async () => {
    await trackCartReminderToOverviewClick();
  };

  return (
    <Link
      href={CheckoutRoutes.cart}
      onClick={handleOnClick}
      prefetch={false}
      className="select-none no-wrap inline-flex justify-center w-full py-3 font-medium text-purple-light border-purple-lighter px-8 border rounded-md shadow-sm focus:outline-none"
    >
      <ShoppingCartIcon className="w-6 h-6 pr-1 inline" aria-hidden="true" />
      {t('app.ui.cart.last_item.go_to_cart_overview')}
    </Link>
  );
}
