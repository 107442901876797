import { FC } from 'react';
import Image from 'next/image';
import { CartReminderProduct } from 'types/product';
import Link from 'next/link';

type Props = {
  product: CartReminderProduct;
  onClick?: () => void;
};

export const CartProductBoxVertical: FC<Props> = ({ product, onClick }) => {
  return (
    <Link
      href={product.link}
      className="text-left flex gap-3 items-start cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-shrink-0 flex-grow-0 relative overflow-hidden">
        <Image
          className="rounded-lg"
          src={product.src}
          width={150}
          height={120}
          alt={product.alt}
          quality={75}
        />
      </div>
      <div className="flex-grow flex-shrink-1 leading-5 w-68 h-16 overflow-hidden">
        <p className="text-sm">{product.title}</p>
      </div>
    </Link>
  );
};
