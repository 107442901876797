import usePageTracking from 'hooks/tracking/usePageTracking';
import { useEffect, useMemo, useState } from 'react';
import PageTagFactory from 'modules/tracking/tags/page/PageTagFactory';
import {
  getImpressionsFromHomePageData,
  getPromoImpressionsFromHomePageBanners,
} from 'modules/tracking/transforms/transforms';
import { useAppSelector } from 'redux/appStore';
import {
  selectAllBanners,
  selectHighlightedProducts,
  selectInYourAreaProducts,
  selectIsProductsInYourAreaFinished,
  selectMainDeal,
  selectMainProducts,
  selectNewProducts,
  selectPopularProducts,
} from 'redux/homePage/homePage.slice';
import { getCartReminderItems } from 'lib/cart/reminder/cartReminder';
import useStore from 'store';
import { CartItem } from 'types/types';
import { isCartLoadingFinished } from 'lib/cart/cartLoading';

export function useTrackHomepage() {
  const { trackPage } = usePageTracking();
  const [hasTracked, setHasTracked] = useState<boolean>(false);

  const mainDeal = useAppSelector(selectMainDeal);
  const newProducts = useAppSelector(selectNewProducts);
  const banners = useAppSelector(selectAllBanners);

  const mainProducts = useAppSelector(selectMainProducts);
  const popularProducts = useAppSelector(selectPopularProducts);
  const highlightedProducts = useAppSelector(selectHighlightedProducts);
  const isLoadingInYourAreaFinished = useAppSelector(selectIsProductsInYourAreaFinished);
  const inYourAreaProducts = useAppSelector(selectInYourAreaProducts);
  const [cart] = useStore('cart');

  const reminderCartItemList = useMemo((): CartItem[] | null => {
    if (!isCartLoadingFinished(cart)) {
      return null;
    }

    return getCartReminderItems(cart);
  }, [cart]);

  useEffect(() => {
    if (hasTracked || !isLoadingInYourAreaFinished || !reminderCartItemList) {
      return;
    }

    const reminderCartItems = reminderCartItemList.map((cartItem) => ({
      product: cartItem.product,
      variant: cartItem.variant,
    }));

    trackPage(
      PageTagFactory.homePage({
        impressions: getImpressionsFromHomePageData({
          mainProducts,
          popularProducts,
          mainDeal,
          highlightedProducts,
          newProducts,
          inYourAreaProducts,
          reminderCartItems,
        }),
        promoImpressions: getPromoImpressionsFromHomePageBanners(banners),
      })
    );

    setHasTracked(true);
  }, [
    banners,
    hasTracked,
    highlightedProducts,
    inYourAreaProducts,
    isLoadingInYourAreaFinished,
    mainDeal,
    mainProducts,
    newProducts,
    popularProducts,
    reminderCartItemList,
    trackPage,
  ]);
}
