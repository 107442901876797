import { CartProductBoxHorizontal } from 'components/cartItem/reminder/CartProductBoxHorizontal';
import Intro from 'components/cartItem/reminder/Intro';
import CartReminderCallToAction from 'components/cartItem/reminder/CartReminderCallToAction';
import GradientHorizontal from 'components/cartItem/reminder/GradientHorizontal';
import { CartReminderProduct, Product } from 'types/product';

type ReminderDesktopProps = {
  products: CartReminderProduct[];
  trackClick: (product: Product, position: number) => Promise<void>;
};

export default function ReminderDesktop({ products, trackClick }: ReminderDesktopProps) {
  return (
    <div className="flex gap-6 px-6 py-3 items-center">
      <div className="flex-shrink w-80 flex-grow-1">
        <Intro />
      </div>
      <div className="relative flex-shrink flex-grow overflow-hidden flex gap-3">
        {products.map((product, index) => (
          <CartProductBoxHorizontal
            key={product.product.id}
            product={product}
            flexBasisMax={products.length}
            onClick={async () => await trackClick(product.product, index + 1)}
          />
        ))}
        {products.length > 1 && <GradientHorizontal />}
      </div>
      <div className="flex-shrink-0 pr-2">
        <CartReminderCallToAction />
      </div>
    </div>
  );
}
