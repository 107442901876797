import { useEffect, useState } from 'react';
import SectionTitle from 'components/SectionTitle';
import { Z_INDEX } from 'constants/common';
import { TrackingProductList } from 'types/tracking';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import { selectGeoFilter } from 'redux/geolocation/geolocation.slice';
import { HOMEPAGE_NUM_LOCATION_BASED_PRODUCTS } from 'constants/product';
import { useTranslation } from 'react-i18next';
import { LiaMapMarkedAltSolid as MapIcon } from 'react-icons/lia';
import { LocalFeelsRoutes } from 'constants/routes/localFeels';
import { useLocationNavigationLink } from 'hooks/location/useLocationNavigationLink';
import { useProductsListHeader } from 'hooks/productList/useProductsListHeader';
import { useResponsive } from 'hooks/useResponsive';
import { capitalizeFirstLetter } from 'lib/strings';
import NavigationButton from 'components/buttons/NavigationButton';
import { ListFooter } from 'components/product/ListFooter';
import { FaArrowRight } from 'react-icons/fa6';
import {
  selectInYourAreaProducts,
  selectIsProductsInYourAreaIdle,
  selectIsProductsInYourAreaPending,
} from 'redux/homePage/homePage.slice';
import { fetchProductsInYourArea } from 'redux/homePage/homePage.thunks';
import { trackNavigationToMaps } from 'modules/tracking/events/events';
import { ProductsRows } from 'components/productList/Row/ProductsRows';

export const LocationProductList = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const geoFilter = useAppSelector(selectGeoFilter);
  const products = useAppSelector(selectInYourAreaProducts);
  const isLoading = useAppSelector(selectIsProductsInYourAreaPending);
  const isIdle = useAppSelector(selectIsProductsInYourAreaIdle);

  const { addLocation } = useLocationNavigationLink();
  const { getProductListHeader } = useProductsListHeader();

  const [title, setTitle] = useState(getProductListHeader({ geoFilter }));

  const allDealsLink = `${LocalFeelsRoutes.TAXON_LISTING_AS_LISTING}${addLocation(
    t('app.ui.href_part.your_location')
  )}`;
  const mapPageLink = `${LocalFeelsRoutes.TAXON_LISTING_AS_MAP}${addLocation(
    t('app.ui.href_part.your_location')
  )}`;

  useEffect(() => {
    setTitle(getProductListHeader({ geoFilter }));
  }, [geoFilter, getProductListHeader]);

  useEffect(() => {
    if (isIdle) dispatch(fetchProductsInYourArea(geoFilter.query));
  }, [dispatch, geoFilter.query, isIdle]);

  return (
    <div className="relative w-full md:mb-0 mb-0">
      <SectionTitle
        title={capitalizeFirstLetter(title, false)}
        href={mapPageLink}
        showLink={true}
        linkContent={t('app.ui.section.section_title_more')}
      />
      <div className={`${Z_INDEX.level10} relative`}>
        <ProductsRows
          products={products}
          isLoading={isLoading}
          loadingSkeletonAmount={HOMEPAGE_NUM_LOCATION_BASED_PRODUCTS}
          trackingListName={TrackingProductList.locationProducts}
        />
      </div>

      <ListFooter>
        <NavigationButton href={allDealsLink} size={'medium'} buttonType={'primary'}>
          {t('app.ui.map.go_to_title', { title: title })}
          <FaArrowRight className={'ml-1'} />
        </NavigationButton>
        {!isMobile && (
          <NavigationButton
            href={mapPageLink}
            size={'medium'}
            buttonType={'secondary'}
            onClick={() => void trackNavigationToMaps()}
          >
            <MapIcon className="mr-2" />
            {t('app.ui.map.go_to_map')}
          </NavigationButton>
        )}
      </ListFooter>
    </div>
  );
};

export default LocationProductList;
