import classNames from 'classnames';
import Intro from 'components/cartItem/reminder/Intro';
import CartReminderCallToAction from 'components/cartItem/reminder/CartReminderCallToAction';
import GradientVertical from 'components/cartItem/reminder/GradientVertical';
import { CartProductBoxVertical } from './CartProductBoxVertical';
import { CartReminderProduct, Product } from 'types/product';

type ReminderMobileProps = {
  products: CartReminderProduct[];
  trackClick: (product: Product, position: number) => Promise<void>;
};

export default function ReminderMobile({ products, trackClick }: ReminderMobileProps) {
  const buttonWrapper = classNames('', {
    'mt-5': products.length === 1,
    'mt-4': products.length > 1,
  });

  return (
    <div className="text-center">
      <Intro />
      <div className="relative justify-center mt-5">
        {products.map((product, index) => (
          <div className="mt-4" key={product.product.id}>
            <CartProductBoxVertical
              product={product}
              onClick={async () => await trackClick(product.product, index + 1)}
            />
          </div>
        ))}
        {products.length > 1 && <GradientVertical />}
      </div>
      <div className={buttonWrapper}>
        <CartReminderCallToAction />
      </div>
    </div>
  );
}
