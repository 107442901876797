import SectionTitle from 'components/SectionTitle';
import { HOME_ROUTES } from 'constants/routes/home';
import { Z_INDEX } from 'constants/common';
import { TrackingProductList } from 'types/tracking';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import { useLocationNavigationLink } from 'hooks/location/useLocationNavigationLink';
import { useAppSelector } from 'redux/appStore';
import { selectPopularProducts } from 'redux/homePage/homePage.slice';
import { ResponsiveProductCard } from 'components/productCard/ResponsiveProductCard';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { ListFooter } from 'components/product/ListFooter';
import { NavigationButton } from 'components/buttons/NavigationButton';

export function PopularProducts() {
  const products = useAppSelector(selectPopularProducts);

  const { t } = useTranslation();
  const { isMobile, lgBreak } = useResponsive();
  const { addLocation } = useLocationNavigationLink();

  return (
    <>
      <SectionTitle
        title={t('app.ui.homepage.most_popular_deals')}
        href={addLocation(HOME_ROUTES.POPULAR_DEALS)}
        showLink={!isMobile}
      />
      <div className="relative">
        <div
          className={`${Z_INDEX.level10} mb-4 relative grid grid-cols-2 lg:grid lg:grid-cols-4 gap-x-4 gap-y-4`}
        >
          {products.slice(0, 4).map((product, index) => (
            <div className="snap-center" key={product.id.toString() + index}>
              <ResponsiveProductCard
                initialRenderSize={PRODUCT_CARD_SIZES.XS}
                product={product}
                className={'w-42 h-full lg:w-full'}
                productList={TrackingProductList.popularProducts}
                productPosition={index + 1}
                imageSizes={`(min-width: ${lgBreak}px) 25vw, 40vw`}
              />
            </div>
          ))}
        </div>

        {isMobile && (
          <ListFooter>
            <NavigationButton
              href={addLocation(HOME_ROUTES.POPULAR_DEALS)}
              size={'medium'}
              buttonType={'primary'}
            >
              {t('app.ui.section.showall')}
            </NavigationButton>
          </ListFooter>
        )}
      </div>
    </>
  );
}
