import { useTranslation } from 'react-i18next';

import useResponsive from 'hooks/useResponsive';
import { TrackingProductList } from 'types/tracking';
import { Product } from 'types/product';
import { ResponsiveProductCard } from 'components/productCard/ResponsiveProductCard';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { PRODUCT_INFO_PLACEMENT_TYPES } from 'lib/productCard/productCard';

const MainDeal = ({ product }: { product: Product | null }) => {
  const { t } = useTranslation();
  const { isLg, isMd } = useResponsive();

  return product ? (
    <>
      <div className="hidden lg:flex flex-wrap items-center w-full h-[400px] my-16 lg:mt-20">
        <div className="flex items-center justify-around w-full h-60 bg-main-deal">
          <div className="flex items-center">
            <h2 className="pr-20 text-3xl text-purple text-center">
              {t('app.ui.main_deal_header')}
            </h2>

            <div className="min-w-[400px] xl:min-w-[600px]">
              <ResponsiveProductCard
                initialRenderSize={isLg ? PRODUCT_CARD_SIZES.M : PRODUCT_CARD_SIZES.L}
                product={product}
                productInfoPlacementType={
                  isLg
                    ? PRODUCT_INFO_PLACEMENT_TYPES.ON_TOP_OF_IMAGE
                    : PRODUCT_INFO_PLACEMENT_TYPES.UNDER_IMAGE
                }
                productList={TrackingProductList.mainDeal}
                productPosition={1}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full lg:hidden">
        <ResponsiveProductCard
          initialRenderSize={PRODUCT_CARD_SIZES.M}
          product={product}
          imageSlider
          productList={TrackingProductList.mainDeal}
          productPosition={1}
        />
      </div>
    </>
  ) : null;
};

export default MainDeal;
