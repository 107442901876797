import { Cart, CartItem, CartReminderProduct } from 'types/types';

const getDefaultImage = (
  cartItem: CartItem
): {
  src: string;
  alt: string;
} => {
  return {
    src: cartItem?.variant.src ?? cartItem.product.images[0].src,
    alt: cartItem?.variant.name ?? cartItem.product.name,
  };
};

export const getCartReminderItems = (cart: Cart | null): CartItem[] => {
  if (!cart?.items.length) return [];

  return cart.items.slice(-2).reverse();
};

export const getCartReminderProducts = (cart: Cart | null): CartReminderProduct[] => {
  const lastItems = getCartReminderItems(cart);

  return lastItems.map((cartItem) => {
    const image = getDefaultImage(cartItem);
    return {
      title: cartItem.product.name,
      alt: image.alt,
      src: image.src,
      link: cartItem.product.href,
      product: cartItem.product,
    };
  });
};
