import Image from 'next/image';
import { CartReminderProduct } from 'types/product';
import Link from 'next/link';
import { FC } from 'react';

export type Props = {
  product: CartReminderProduct;
  flexBasisMax: number;
  onClick?: () => void;
};

export const CartProductBoxHorizontal: FC<Props> = ({ product, flexBasisMax, onClick }) => (
  <Link
    href={product.link}
    className={`text-left basis-1/${flexBasisMax} flex gap-3 items-start cursor-pointer`}
    onClick={onClick}
  >
    <div className="flex-shrink-0 relative overflow-hidden w-28 h-20 rounded-lg">
      <Image className="object-cover" src={product.src} fill alt={product.alt} />
    </div>
    <div className="flex-grow flex-shrink-1 leading-5 pt-2 h-16 overflow-hidden">
      <p className="text-sm">{product.title}</p>
    </div>
  </Link>
);
