export default function GradientVertical() {
  return (
    <div className={'absolute h-30 bottom-zero inset-x-0'}>
      <div
        className={'bg-gradient-to-t from-bg-grey-light to-transparent h-12 bottom-zero inset-x-0'}
      />
      <div className={'h-5 bg-grey-light'}></div>
    </div>
  );
}
