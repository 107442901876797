import { useTranslation } from 'react-i18next';

export default function Intro() {
  const { t } = useTranslation();

  return (
    <>
      <h2 className={'font-medium text-xl pt-1'}>{t('app.ui.cart.last_item.finish_order')}</h2>
      <p className={'mt-1.5 font-light text-sm'}>
        {t('app.ui.cart.last_item.there_are_still_items_in_cart')}
      </p>
    </>
  );
}
