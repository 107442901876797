import { Cart } from 'types/types';

const hasCartInLocalStorage = (): boolean =>
  Boolean(typeof window !== 'undefined' && localStorage.getItem('useStore.cart'));

export const isCartLoadingFinished = (cart: Cart | null): boolean => {
  if (!hasCartInLocalStorage()) return true;

  return Boolean(cart?.id);
};
