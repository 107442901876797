import useStore from 'store';
import { useMemo, useState } from 'react';
import { IoClose as CloseIcon } from 'react-icons/io5';
import useCartReminderViewCount from 'hooks/cartItem/useCartReminderViewCount';
import useResponsive from 'hooks/useResponsive';
import ReminderMobile from 'components/cartItem/reminder/ReminderMobile';
import ReminderDesktop from './ReminderDesktop';
import { getCartReminderProducts } from 'lib/cart/reminder/cartReminder';
import { CartReminderProduct, Product } from 'types/product';
import { productClick } from 'modules/tracking/events/events';
import { TrackingProductList } from 'types/tracking';

export default function CartReminder() {
  const { addView, hasReachedMaxViewCount } = useCartReminderViewCount();
  const [cart] = useStore('cart');
  const { isMobile } = useResponsive();
  const [show, setShow] = useState<boolean>(Boolean(!hasReachedMaxViewCount()));

  const products = useMemo((): CartReminderProduct[] => {
    return getCartReminderProducts(cart);
  }, [cart]);

  if (!show || products.length < 1 || hasReachedMaxViewCount()) return null;

  const trackClick = async (product: Product, position: number) => {
    await productClick({
      product,
      productList: TrackingProductList.cartReminderProducts,
      productPosition: position,
    });
  };

  const handleClose = () => {
    addView();
    setShow(false);
  };

  if (!products.length) return null;
  return (
    <div className="w-full bg-white relative drop-shadow-lg rounded-lg pt-3 my-6 pb-4 px-3">
      <CloseIcon
        onClick={handleClose}
        className="absolute right-3 top-3 h-5 w-5 cursor-pointer"
        aria-hidden="true"
      />
      {isMobile ? (
        <ReminderMobile products={products} trackClick={trackClick} />
      ) : (
        <ReminderDesktop products={products} trackClick={trackClick} />
      )}
    </div>
  );
}
